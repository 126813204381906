export class Patterns {
  // static email = '[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'
  static email = /^[a-zA-Z0-9][\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/
  static enTxt = '^[a-zA-Z ]+$';
  static arTxt = '^(?=.*[\u0600-\u06FF])[\u0600-\u06FF ]+$';
  static enTxt_Numbers = '^[a-zA-Z0-9 ]+$';
  static noSpecialTxt = '^[a-zA-Z\u0600-\u06FF ]+$';
  static number = '^[0-9]+$';
  static float = '([0-9]+[.])*[0-9]+(?:[0-9]+)?$';
  static website = /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
  static enTxtWithSpecial = "^[a-zA-Z0-9- !\"'@#$%^&*()<>‘?;_–+.,:\|=/{}^\S\n\r]+$"
  static arTxtWithSpecial = "^[\u0600-\u06FF0-9- !\"'@#$%^&*()<>‘?;_–+.,:\|=/{}^\S\n\r]+$"
  // static arTxtWithSpecial = "^(?=.*[\u0600-\u06FF0-9- ])[\u0600-\u06FF0-9- !\"'@#$%^&*()<>?;_+.,:\|=/{}^\S\n]+$"
  static price = '^0*([1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$'
  static phoneNumber = '[+0-9]+'
  static password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*+={}?<>"'(),])(?!.*\s).{8,}$/
  static percentage = '^100$|^[0-9]{1,2}$|^[0-9]{1,2}\,*[.][0-9]{1,3}$'
  static regularNumber = '^0*(?:[1-9][0-9]{1,6})$'
  // static website = "(https://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-_]*/?";
  // static percentage = '^100$|^[0-9]{1,2}$|^[0-9]{1,2}\,[0-9]{1,3}$'
}
